import styled from 'styled-components';

const Layout = styled.div`
  display: grid;
  grid-template: auto 1fr/ 1fr auto 1fr;
  grid-template-areas: '. center .';
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export default Layout;
