import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { compareAsc, format } from 'date-fns';
import FleetCard from './fleetcard';
import { FleetContext } from '../utilities/contexts';

const BREAKPOINT = '700px';

const FleetCards = () => {
  const { fleets, sort, showKills } = useContext(FleetContext);
  const sortedFleets = useMemo(() => {
    switch (sort.key) {
      case 'size':
        return fleets
          .slice()
          .sort((a, b) => (sort.dir === 'desc' ? 1 : -1) * (b.members.length - a.members.length));
      case 'recent':
        return fleets
          .slice()
          .sort(
            (a, b) => (sort.dir === 'desc' ? 1 : -1)
              * compareAsc(
                new Date(`${format(b.lastSeen, 'YYYY-MM-DDTHH:mm:ss')}Z`),
                new Date(`${format(a.lastSeen, 'YYYY-MM-DDTHH:mm:ss')}Z`),
              ),
          );
      default:
        return fleets;
    }
  }, [fleets, sort]);
  return (
    <FleetContainer>
      {sortedFleets.map(fleet => (
        <FleetCard key={`fleet${fleet.id}`} {...{ fleet, showKills }} />
      ))}
    </FleetContainer>
  );
};

const FleetContainer = styled.div`
  grid-area: 1 / 1 / -1 / -1;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 40rem 0 10rem;
  @media only screen and (min-width: ${BREAKPOINT}) {
    margin: 40rem 0 0;
    padding: 10rem 0;
  }
`;

export default FleetCards;
