import { last } from 'lodash';

const shortener = (name) => {
  switch (name) {
    case 'Caldari Navy Hookbill':
      return 'Hookbill';
    case 'Imperial Navy Slicer':
      return 'Slicer';
    case 'Federation Navy Comet':
      return 'Comet';
    case 'Republic Fleet Firetail':
      return 'Firetail';
    case "Capsule - Genolution 'Auroral' 197-variant":
      return 'Capsule';
    case 'Victorieux Luxury Yacht':
      return 'Yacht';
    case "'Excavator' Mining Drone":
      return 'Excavator';
    case 'Tenebrex Cyno Jammer':
      return 'Cyno Jammer';
    case 'Ansiblex Jump Gate':
      return 'Jump Gate';
    default:
      if (name.match(/(\w) Navy Issue/)) return `Navy ${name.split(' ')[0]}`;
      if (name.match(/(\w) Fleet Issue/)) return `Fleet ${name.split(' ')[0]}`;
      if (name.match(/(\w) Encounter Surveillance System/)) return `${name.split(' ')[0]} ECS`;
      if (name.match(/(\w) Warp Disruptor (\w)/)) return `${last(name.split(' ')) === 'I' ? 'T1' : 'T2'} Bubble ${name.split(' ')[1][0]}`;
      if (name.match(/(\w) Control Tower (\w)/)) return `${name.split(' ')[0]} Tower ${last(name.split(' '))[0]}`;
      return name;
  }
};

export default shortener;
