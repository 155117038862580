import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AppAuthHelper from 'appauthhelper';
import styled from 'styled-components';
import { UserContext } from '../utilities/contexts';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ name: '', id: '' });
  const [modal, setModal] = useState(false);
  useEffect(() => {
    AppAuthHelper.init({
      clientId: process.env.EVE_API_CLIENT_ID,
      authorizationEndpoint: 'https://login.eveonline.com/v2/oauth/authorize/',
      tokenEndpoint: 'https://login.eveonline.com/v2/oauth/token/',
      revocationEndpoint: 'https://login.eveonline.com/v2/oauth/revoke/',
      renewCooldownPeriod: 1,
      oidc: false,
      resourceServers: {
        'https://esi.evetech.net/characters':
          'publicData esi-location.read_location.v1 esi-ui.write_waypoint.v1',
      },
      interactionRequiredHandler() {
        AppAuthHelper.iframeRedirect(document.getElementById('loginIframe'));
      },
      tokensAvailableHandler: (claims) => {
        setModal(false);
        console.log(claims);
      },
      redirectUri: `${process.env.APP_URL}appAuthHelperRedirect`,
    });
  }, []);
  const login = () => {
    setModal(true);
    AppAuthHelper.getTokens();
  };
  return (
    <UserContext.Provider value={{ user, login }}>
      {typeof document !== 'undefined'
        && ReactDOM.createPortal(
          <>
            {modal && (
              <Modal onClick={() => setModal(false)}>
                <Iframe id="loginIframe" title="Login" />
              </Modal>
            )}
          </>,
          document.getElementById('modal-root'),
        )}
      {children}
    </UserContext.Provider>
  );
};

const Modal = styled.div`
  position: absolute;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template: 1fr auto 1fr/ 1fr auto 1fr;
  grid-template-areas: '. . .' '. center .' '. . .';
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  grid-area: center;
  width: 500px;
  height: 500px;
`;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
