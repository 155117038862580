import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FleetContext } from '../utilities/contexts';

const FleetProvider = ({ children }) => {
  const [fleets, setFleets] = useState([]);
  const [sort, setSortState] = useState({
    key:
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('fleet_tracker:sortKey') || 'recent'
        : 'recent',
    dir:
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('fleet_tracker:sortDir') || 'desc'
        : 'desc',
  });
  const setSort = ({ key, dir }) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('fleet_tracker:sortKey', key);
      localStorage.setItem('fleet_tracker:sortDir', dir);
    }
    setSortState({ key, dir });
  };
  const [showKills, setShowKillsState] = useState(
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('fleet_tracker:showKills') === 'true'
      : false,
  );
  const setShowKills = (show) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('fleet_tracker:showKills', show);
    }
    setShowKillsState(show);
  };
  const toggleKills = () => setShowKills(!showKills);
  const updateFleets = async () => {
    const response = await axios.get(process.env.FLEET_TRACKER_API_URL);
    setFleets(response.data.fleets);
  };
  useEffect(() => {
    updateFleets();
    const interval = setInterval(updateFleets, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <FleetContext.Provider
      value={{
        fleets,
        sort,
        setSort,
        showKills,
        toggleKills,
      }}
    >
      {children}
    </FleetContext.Provider>
  );
};

FleetProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FleetProvider;
