import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Bubble from '../icons/Bubble.svg';
import Fleet from '../icons/Fleet.svg';
import Shield from '../icons/Shield.svg';
import Armor from '../icons/Armor.svg';

const FleetIcons = ({ ships }) => {
  const bubbles = useMemo(
    () => ships.reduce((acc, ship) => ([541, 894].includes(ship.type) ? acc + ship.count : acc), 0),
    [ships],
  );
  const commands = useMemo(
    () => ships.reduce((acc, ship) => ([1534, 540].includes(ship.type) ? acc + ship.count : acc), 0),
    [ships],
  );
  const shield = useMemo(
    () => ships.reduce(
      (acc, ship) => ([
        'Burst',
        'Bantam',
        'Osprey',
        'Scythe',
        'Kirin',
        'Scalpel',
        'Etana',
        'Basilisk',
        'Scimitar',
        'Loggerhead',
        'Rorqual',
        'Orca',
        'Lif',
        'Minokawa',
      ].includes(ship.ship)
        ? acc + ship.count
        : acc),
      0,
    ),
    [ships],
  );
  const armor = useMemo(
    () => ships.reduce(
      (acc, ship) => ([
        'Navitas',
        'Tormentor',
        'Augoror',
        'Rodiva',
        'Exequror',
        'Deacon',
        'Thalia',
        'Guardian',
        'Oneiros',
        'Rabisu',
        'Zarmazd',
        'Apostle',
        'Ninazu',
        'Dagon',
        'Nestor',
      ].includes(ship.ship)
        ? acc + ship.count
        : acc),
      0,
    ),
    [ships],
  );

  return (
    <div>
      {commands > 0 && (
        <Icon>
          <Fleet />
          {`x${commands}`}
        </Icon>
      )}
      {bubbles > 0 && (
        <Icon>
          <Bubble />
          {`x${bubbles}`}
        </Icon>
      )}
      {shield > 0 && (
        <Icon>
          <Shield />
          {`x${shield}`}
        </Icon>
      )}
      {armor > 0 && (
        <Icon>
          <Armor />
          {`x${armor}`}
        </Icon>
      )}
    </div>
  );
};

const Icon = styled.span`
  font-family: 'Space Mono', monospace;
  font-size: 20rem;
  display: inline-block;
  svg {
    height: 20rem;
    width: 20rem;
    display: inline-block;
    margin-left: 10rem;
    * {
      stroke-width: 7rem;
      stroke: #272727;
    }
  }
`;

FleetIcons.propTypes = {
  ships: PropTypes.arrayOf(
    PropTypes.shape({
      ship: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

export default FleetIcons;
