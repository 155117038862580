const buttonStyle = `
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 15rem;
  padding: 5rem 20rem;
  text-decoration: none;
  border: 1rem solid #272727;
  color: #272727;
  background: none;
  border-radius: 1000rem;
  appearance: none;
  &:hover {
    background: #272727;
    color: #e5e5e5;
  }
`;

const paneStyle = `
  background: rgba(190, 190, 190, 0.75) ;
`;

export { buttonStyle, paneStyle };
