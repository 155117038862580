import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FleetContext, UserContext } from '../utilities/contexts';
import { buttonStyle, paneStyle } from '../utilities/styles';
import useMedia from '../hooks/useMedia';

const BREAKPOINT = '700px';

const nothing = (e) => {
  e.persist();
  e.nativeEvent.stopImmediatePropagation();
  e.stopPropagation();
};

const nav = () => {
  const {
    sort, setSort, showKills, toggleKills,
  } = useContext(FleetContext);
  const [menu, setMenu] = useState(false);
  // const mobile = useMedia(['(min-width: 700px)'], [false], true);
  const { login } = useContext(UserContext);
  return (
    <>
      <Bar {...{ menu }} onClick={() => setMenu(false)}>
        <Options>
          <Label>Sort by:</Label>
          <Select
            value={sort.key}
            onChange={e => setSort({ key: e.target.value, dir: sort.dir })}
            onClick={nothing}
          >
            <option value="size">Size</option>
            <option value="recent">Recent</option>
          </Select>
          <Select
            value={sort.dir}
            onChange={e => setSort({ dir: e.target.value, key: sort.key })}
            onClick={nothing}
          >
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </Select>
          <Button onClick={() => toggleKills()}>{showKills ? 'Hide Kills' : 'Show Kills'}</Button>
        </Options>
        <Button onClick={login}>Log In to Eve</Button>
      </Bar>
      <Hamburger onClick={() => setMenu(!menu)} {...{ menu }}>
        <svg
          width="39"
          height="9"
          viewBox="0 0 39 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L19.5 8L38 1"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Hamburger>
    </>
  );
};

const Bar = styled.nav`
  grid-area: 1 / 1 / -1 / -1;
  height: auto;
  width: auto;
  align-self: flex-start;
  z-index: ${props => (props.menu ? 6 : -6)};
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  border-radius: 10rem;
  margin: 50rem 10rem 0 10rem;
  transition: transform 0.2s ease-out;
  transform: translate3d(0, ${props => (props.menu ? '0' : '-100%')}, 0);
  background: rgba(190, 190, 190, 1);
  @media only screen and (min-width: ${BREAKPOINT}) {
    ${paneStyle}
    transform: translate3d(0, 0, 0);
    margin: 0;
    border-radius: 0;
    justify-content: space-between;
    z-index: 5;
    height: 40rem;
    flex-direction: row;
  }
`;
const Options = styled.span`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  @media only screen and (min-width: ${BREAKPOINT}) {
    flex-direction: row;
  }
`;

const Label = styled.h3`
  padding: 10rem 0;
  display: inline;
  font-weight: normal;
  font-size: 15rem;
  margin: 0 0 0 10rem;
`;

const Select = styled.select`
  margin: 10rem;
  align-self: center;
  ${buttonStyle}
`;

const Button = styled.button`
  margin: 10rem;
  ${buttonStyle}
`;

const Hamburger = styled.button`
  grid-area: 1 / 1 / -1 / -1;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  justify-self: flex-end;
  height: 40rem;
  width: 40rem;
  border-radius: 20rem;
  border: none;
  ${paneStyle}
  margin: 0 10rem;
  svg {
    transition: transform 0.2s ease-out;
    transform: scale3d(1, ${props => (props.menu ? -1 : 1)}, 1);
    path {
      stroke-width: 2rem;
      stroke: #272727;
    }
  }
  @media only screen and (min-width: ${BREAKPOINT}) {
    display: none;
  }
`;

const LinkButton = styled.a`
  margin: 0 10rem;
  justify-self: flex-end;
  ${buttonStyle}
`;

export default nav;
