import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Background from '../components/background';
import FleetCards from '../components/fleetcards';
import Nav from '../components/nav';
import '../index.css';
import FleetProvider from '../components/fleetprovider';
import UserProvider from '../components/userprovider';

const IndexPage = () => (
  <Layout>
    <SEO />
    <Background />
    <UserProvider>
      <FleetProvider>
        <Nav />
        <FleetCards />
      </FleetProvider>
    </UserProvider>
  </Layout>
);

export default IndexPage;
