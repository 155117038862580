import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import shortener from '../utilities/shortener';
import Capsule from '../icons/Capsule.svg';
import Shuttle from '../icons/Shuttle.svg';
import Rookie from '../icons/Rookie.svg';
import Frigate from '../icons/Frigate.svg';
import Destroyer from '../icons/Destroyer.svg';
import Cruiser from '../icons/Cruiser.svg';
import Battlecruiser from '../icons/Battlecruiser.svg';
import Battleship from '../icons/Battleship.svg';
import Carrier from '../icons/Carrier.svg';
import Dreadnought from '../icons/Dreadnought.svg';
import Auxiliary from '../icons/Auxiliary.svg';
import Supercarrier from '../icons/Supercarrier.svg';
import Titan from '../icons/Titan.svg';
import Miner from '../icons/Miner.svg';
import Barge from '../icons/Barge.svg';
import Industrial from '../icons/Industrial.svg';
import Command from '../icons/Command.svg';
import Freighter from '../icons/Freighter.svg';
import Astrahus from '../icons/Astrahus.svg';
import Athanor from '../icons/Athanor.svg';
import Azbel from '../icons/Azbel.svg';
import Fortizar from '../icons/Fortizar.svg';
import Keepstar from '../icons/Keepstar.svg';
import Raitaru from '../icons/Raitaru.svg';
import Sotiyo from '../icons/Sotiyo.svg';
import Tatara from '../icons/Tatara.svg';
import Surveillance from '../icons/Surveillance.svg';
import Tractor from '../icons/Tractor.svg';
import Fibo from '../icons/Fibo.svg';
import Fighter from '../icons/Fighter.svg';
import Warp from '../icons/Warp.svg';
import Scan from '../icons/Scan.svg';
import MJD from '../icons/MJD.svg';
import Cyno from '../icons/Cyno.svg';
import Depot from '../icons/Depot.svg';
import Ewar from '../icons/Ewar.svg';
import Excavator from '../icons/Excavator.svg';
import POS from '../icons/POS.svg';
import Hardening from '../icons/Hardening.svg';
import Battery from '../icons/Battery.svg';
import Corporate from '../icons/Corporate.svg';
import Maintenance from '../icons/Maintenance.svg';
import Missile from '../icons/Missile.svg';
import Damp from '../icons/Damp.svg';
import Gate from '../icons/Gate.svg';
import Customs from '../icons/Customs.svg';
import Jammer from '../icons/Jammer.svg';

const Ship = ({
  time, name, children, type,
}) => {
  const shortName = useMemo(() => shortener(name), [name]);
  return (
    <ShipName>
      {time && <Time>{format(time, 'HH:mm')}</Time>}
      <Icon {...{ name, type }} />
      {shortName}
      {children && ` ${children}`}
    </ShipName>
  );
};

const Icon = ({ type, name }) => {
  switch (name) {
    case 'Venture':
      return <Miner />;
    case 'Astrahus':
      return <Astrahus />;
    case 'Athanor':
      return <Athanor />;
    case 'Azbel':
      return <Azbel />;
    case 'Fortizar':
      return <Fortizar />;
    case 'Keepstar':
      return <Keepstar />;
    case 'Raitaru':
      return <Raitaru />;
    case 'Sotiyo':
      return <Sotiyo />;
    case 'Tatara':
      return <Tatara />;
    default:
  }
  switch (type) {
    case 29:
      return <Capsule />;
    case 31:
      return <Shuttle />;
    case 237:
      return <Rookie />;
    case 324:
    case 830:
    case 893:
    case 25:
    case 831:
    case 1527:
    case 834:
    case 1022:
      return <Frigate />;
    case 420:
    case 541:
    case 1305:
    case 1534:
      return <Destroyer />;
    case 26:
    case 358:
    case 832:
    case 833:
    case 906:
    case 894:
    case 963:
    case 1972:
      return <Cruiser />;
    case 419:
    case 1201:
    case 540:
      return <Battlecruiser />;
    case 27:
    case 898:
    case 900:
      return <Battleship />;
    case 547:
      return <Carrier />;
    case 485:
      return <Dreadnought />;
    case 1538:
      return <Auxiliary />;
    case 659:
      return <Supercarrier />;
    case 30:
      return <Titan />;
    case 1283:
      return <Miner />;
    case 463:
    case 543:
      return <Barge />;
    case 380:
    case 1202:
    case 28:
      return <Industrial />;
    case 513:
    case 883:
    case 902:
      return <Freighter />;
    case 941:
      return <Command />;
    case 1273:
      return <Surveillance />;
    case 1250:
      return <Tractor />;
    case 1653:
      return <Fibo />;
    case 1652:
      return <Fighter />;
    case 1537:
      return <Ewar />;
    case 1246:
      return <Depot />;
    case 101:
      return <Excavator />;
    case 1249:
      return <Cyno />;
    case 1276:
      return <MJD />;
    case 1275:
      return <Scan />;
    case 361:
      return <Warp />;
    case 365:
      return <POS />;
    case 444:
      return <Hardening />;
    case 383:
    case 430:
    case 426:
    case 449:
      return <Battery />;
    case 417:
      return <Missile />;
    case 440:
    case 441:
    case 443:
    case 837:
    case 877:
      return <Damp />;
    case 471:
      return <Corporate />;
    case 363:
      return <Maintenance />;
    case 1025:
      return <Customs />;
    case 1408:
      return <Gate />;
    case 2016:
      return <Jammer />;
    default:
      return [];
  }
};

const ShipName = styled.span`
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 16rem;
  svg {
    height: 16rem;
    width: 16rem;
    display: inline-block;
    margin-right: 5rem;
    * {
      stroke-width: 6rem;
      stroke: #272727;
    }
  }
`;

const Time = styled.span`
  font-size: 16rem;
  font-family: 'Space Mono', monospace;
  margin-right: 5rem;
`;

Ship.propTypes = PropTypes.shape({
  time: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.string,
  type: PropTypes.string.isRequired,
}).isRequired;

export default Ship;
