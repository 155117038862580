import { distanceInWordsToNow, format } from 'date-fns';
import { countBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Ship from './ship';
import FleetIcons from './fleeticons';
import { buttonStyle, paneStyle } from '../utilities/styles';

const FleetCard = (props) => {
  const { fleet, showKills } = props;
  const [toggle, setToggle] = useState(showKills);
  const systems = useMemo(
    () => fleet.killmails.reduce((acc, cur) => {
      if (acc[0] && acc[0].system === cur.system) {
        return [
          {
            system: cur.system,
            killmails: [cur, ...acc[acc.length - 1].killmails],
          },
          ...acc.slice(0, -1),
        ];
      }
      return [
        {
          system: cur.system,
          killmails: [cur],
        },
        ...acc,
      ];
    }, []),
    [fleet],
  );
  const composition = useMemo(() => {
    const ships = countBy(fleet.members.map(member => member.ship));
    const types = uniqBy(fleet.members, member => member.ship);
    return types
      .map(member => ({ ship: member.ship, type: member.type, count: ships[member.ship] }))
      .sort((a, b) => b.count - a.count);
  }, [fleet]);
  const participants = useMemo(() => {
    const orgs = countBy(
      fleet.members.map(member => (member.alliance ? member.alliance : member.corporation)),
    );
    return Object.keys(orgs)
      .map(org => ({ name: org, count: orgs[org] }))
      .sort((a, b) => b.count - a.count);
  }, [fleet]);
  useEffect(() => setToggle(showKills), [showKills]);
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {`${fleet.members.length} Pilots in `}
          <A href={`https://evemaps.dotlan.net/region/${encodeURI(fleet.region)}`}>
            {fleet.region}
          </A>
        </CardTitle>
        <FleetIcons ships={composition} />
      </CardHeader>
      <CardDate>
        {`${distanceInWordsToNow(
          new Date(`${format(fleet.lastSeen, 'YYYY-MM-DDTHH:mm:ss')}Z`),
        )} ago`}
      </CardDate>
      <FleetLocs>
        {systems.map((system, index) => (
          <System key={`${fleet.id}sys${system.system}`} toggle={toggle}>
            <SystemName href={`https://evemaps.dotlan.net/system/${encodeURI(system.system)}`}>
              {`${index > 0 ? '<- ' : ''}${system.system}`}
            </SystemName>
            <Aul {...{ toggle }}>
              {system.killmails.map(killmail => (
                <Li key={`${fleet.id}sys${killmail.id}`}>
                  <A href={`https://zkillboard.com/kill/${killmail.id}`}>
                    <Ship name={killmail.ship} type={killmail.type} time={killmail.time} />
                  </A>
                </Li>
              ))}
            </Aul>
          </System>
        ))}
      </FleetLocs>
      <ToggleButton onClick={() => setToggle(!toggle)} toggle={toggle}>
        <svg
          width="39"
          height="9"
          viewBox="0 0 39 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L19.5 8L38 1"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </ToggleButton>
      <FleetInfo>
        <section>
          <h3>Composition</h3>
          <Ul>
            {composition.map(ship => (
              <Li>
                <Ship key={`${fleet.id}comp${ship.ship}`} name={ship.ship} type={ship.type}>
                  {`x${ship.count}`}
                </Ship>
              </Li>
            ))}
          </Ul>
        </section>
        <section>
          <h3>Participants</h3>
          <Ul>
            {participants.map(participant => (
              <Li key={participant.name}>{`${participant.name} x${participant.count}`}</Li>
            ))}
          </Ul>
        </section>
      </FleetInfo>
    </Card>
  );
};

FleetCard.propTypes = {
  fleet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    region: PropTypes.string.isRequired,
    lastSeen: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
  }).isRequired,
  showKills: PropTypes.bool.isRequired,
};

const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.h2`
  text-align: left;
  display: inline;
  font-family: 'Space Mono', monospace;
  font-size: 20rem;
  font-weight: normal;
  margin: 0;
`;

const CardDate = styled.h4`
  text-align: left;
  font-size: 16rem;
  font-weight: normal;
  margin: 0;
`;

const FleetLocs = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
`;

const System = styled.section`
  display: flex;
  flex-direction: column;
  margin: 10rem;
`;

const Li = styled.li`
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 5rem;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
const Aul = styled.ul`
  height: ${props => (props.toggle ? 'auto' : 0)};
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const A = styled(OutboundLink)`
  text-decoration: none;
  color: #272727;
  &:hover {
    color: #efefef;
    path {
      stroke: #efefef;
    }
  }
`;

const SystemName = styled(OutboundLink)`
  align-self: center;
  ${buttonStyle}
  font-size: 20rem;
  font-family: 'Space Mono', monospace;
`;

const ToggleButton = styled.button`
  width: 39rem;
  height: 9rem;
  display: inline-block;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    transform: ${props => (props.toggle ? 'scale(1,-1)' : 'scale(1,1)')};
    transition: transform 0.2s ease-out;
    path {
      stroke-width: 2rem;
      stroke: #272727;
    }
  }
`;

const FleetInfo = styled.div`
  margin-top: 10rem;
  text-align: left;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  h3 {
    font-family: 'Space Mono', sans-serif;
    font-weight: normal;
    font-size: 20rem;
    margin: 0;
  }
  section {
    margin: 0 10rem;
  }
`;

const Card = styled.section`
  max-width: 100vw;
  width: 500rem;
  margin: 10rem;
  border-radius: 10rem;
  padding: 15rem;
  overflow: hidden;
  font-size: 16rem;
  text-align: center;
  ${paneStyle}
`;

export default FleetCard;
